import React, { useRef } from "react";

// const AddressModel = ({
//   isDialogue,
//   isWalletSection,
//   showModel,
//   children,
//   setShowModel = () => {},
//   title,
// }) => {
const AddressModel = ({
  isOpen,
  onClose,
  addresses,
  setSelectedAddressData,
  selectedAddressData,
  selectAddressHandle,
}) => {
  const modalRef = useRef(null);
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose(false);
    }
  };

  if (!isOpen) return null;
  return (
    <>
      <div className="modal-overlay" onClick={handleClickOutside}>
        <div className="modal-container" ref={modalRef}>
          <div className="modal-header">
            <h2>Select Address</h2>
            <button onClick={onClose} className="modal-close-button">
              &times;
            </button>
          </div>
          {addresses?.length === 0 ? (
            <div
              style={{
                height: "60vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "gray", fontSize: "20px" }}>
                No Address Found
              </span>
            </div>
          ) : (
            <div className="modal-grid">
              {addresses?.map((address, index) => (
                <div
                  onClick={() => {
                    selectAddressHandle(address)
                    setSelectedAddressData(address);
                    onClose(false);
                  }}
                  key={index}
                  style={{
                    border: `1px solid ${
                      address?._id === selectedAddressData?._id
                        ? "#A9772B"
                        : "#ddd"
                    }`,
                  }}
                  className="modal-card"
                >
                  <h3>
                    {address.firstname}{" "}
                    {address?.label ? `(${address?.label})` : ``}{" "}
                  </h3>
                  <span>{address.address1}</span>
                  {address.address2 && <span>, {address.address2}</span>}
                  {address.city && <span>, {address.city}</span>}
                  {address.zipCode && <span>, {address.zipCode}</span>}
                  {address.country && <span>, {address.country}</span>}
                  <p className="phone">📞 {address.phone}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddressModel;
